import React from "react";
import "./App.css";
import Home from "./Pages/Home";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./Elements/1NavigationBar";
import Footer from "./Elements/ZFooter";
import Resume from "./Pages/Resume";
import ShareablePDF from "./Pages/ShareablePDF";
import MasalaTwist from "./Pages/Clients/masalaTwist/MasalaTwist";
import Gilliard from "./Pages/Clients/gilliard/Gilliard";
import EducationPage from "./Pages/EducationPage";
import GenericPost from "./Pages/Blog/Running/GenericPost";
import Jola from "./Pages/Messages/Jola/Jola";
import Rachel from "./Pages/Clients/rachel/Rachel";
import KitchenSink from "./Pages/KitchenSink";
import PageNotFound from "./Pages/PageNotFound";

const App = () => {
  // window.history.pushState({}, '', '/') //AMAZING

  return (
    <React.Fragment>
      <Navbar />
      <Router>
        {/* ///////////////////////////////////////////////////////// */}
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/resume" component={Resume} />
          <Route path="/pdf" component={ShareablePDF} />
          <Route path="/courses" component={EducationPage} />
          <Route path="/MasalaTwist" component={MasalaTwist} />
          <Route path="/rachel" component={Rachel} />
          <Route path="/clients/Gilliard" component={Gilliard} />
          <Route path="/blog/genericpost" component={GenericPost} />
          <Route path="/family/jola" component={Jola} />
          <Route path="/kitchensink" component={KitchenSink} />

          <Route render={() => <PageNotFound />} />
        </Switch>
        {/* ///////////////////////////////////////////////////////// */}

        <Footer />
      </Router>
    </React.Fragment>
  );
};

export default App;
