import React from "react";
import { Container, Navbar } from "react-bootstrap";
// import "./clients.css";
import Fade from "react-reveal/Fade";
import Division from "../../../Elements/Division";

const Rachel = () => {
  return (
    <span id="clients" style={{ overflowX: "hidden" }}>
      <Navbar style={{ background: "#7573bf" }}>
        <Container>
          <Navbar.Brand style={{ color: "white" }}>
            <strong>Coach Rae</strong>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container style={{ minHeight: "90vh" }}>
        <main style={{ padding: "2rem 0" }}>
          <Fade>
            <h3 style={{ marginBottom: "2rem" }}>Current status:</h3>
            <div style={{ display: "inline" }}></div>
            <p>
              <strong>Hello Rachel!</strong> You may find our first iteration of
              your resume{" "}
              <a
                href="http://www.alfredorafael.com/wp-content/uploads/2025/01/Rachel_Leonard_Resume.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <strong>here</strong>
              </a>
              . Please print, review, red pen in hand, and I look forward to
              continue working with you so that it reflects your skillsets for
              the desired job(s), while appealing to hiring managers who know
              very little about the field, and teacher jargon. We could meet via
              Zoom or phone to discuss any updates, so keep me posted with your
              availability.{" "}
              <u>
                Motola mentioned that adding PLC to the resume would be a good
                idea
              </u>
              , so you ladies may wanna to discuss that as well.
            </p>

            <h3 style={{ marginBottom: "2rem", marginTop: "2rem" }}>
              Next Steps:
            </h3>
          </Fade>
          <p>
            Other than working with our current v2 of your resume, I look
            forward to working with you{" "}
            <strong style={{ color: "#404040" }}>
              <i>updating your LinkedIn presence</i>
            </strong>{" "}
            and to provide some{" "}
            <strong style={{ color: "#404040" }}>
              <i>interviewing tips </i>
            </strong>
            that may come handy after years of stability in your current
            role(s). Keep me and Motola posted!
          </p>
          Till soon!
          <br />
          <strong>Alfredo R. Pabón</strong>
          <Division />
        </main>
      </Container>
    </span>
  );
};

export default Rachel;
